// @flow
// base
// import { ArcherContainer, ArcherElement } from 'react-archer'
import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './MightycInfographic.lang'
import cn from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
// components
import { Container } from '../../../../components/basic/Grid'
// assets
import productDesktop from '../../../../images/products/mightyc/mightyc-teaser2-desktop.png'
import productTablet from '../../../../images/products/mightyc/mightyc-teaser2-tablet.png'
import productTablet2x from '../../../../images/products/mightyc/mightyc-teaser2-tabletx2.png'
import productMobile from '../../../../images/products/mightyc/mightyc-teaser2-mobile.png'
import productMobile2x from '../../../../images/products/mightyc/mightyc-teaser2-mobilex2.png'
import contentArrows from '../../../../images/products/mightyc/infographic-mightyc-arrows.svg'
import contentArrowsMobile from '../../../../images/products/mightyc/infographic-mightyc-arrows-mobile.svg'
import devicesArrows from '../../../../images/products/mightyc/infographic-mightyc-arrows-devices.svg'
import devicesArrowsMobile from '../../../../images/products/mightyc/infographic-mightyc-arrows-mobile-devices.svg'
// styles
import baseStyles from './MightycInfographic.scss'

type Props = {
  ...StyleProps
}

const MightycInfographic = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.products}>
          <ScrollAnimation className={styles.topAnimationContainer} animateIn="fadeInUp" animateOnce delay={100}>
            <span className={styles.title}>
              {formatMessage(messages.title)}
              <b className={styles.lastLetter}>.</b>
            </span>
            <span className={styles.contentText}>{formatMessage(messages.contentText)}</span>
          </ScrollAnimation>
        </div>
        <div className={styles.infographic}>
          <div className={styles.colLeft}>
            <div className={styles.itemWrapper}>
              <ScrollAnimation className={styles.contentContainer} animateIn="fadeIn" animateOnce delay={200}>
                <div className={styles.productIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-checklist')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel1)}</span>
                </div>
                <div className={styles.spacerBox} />
                <div className={styles.productIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-system-integration')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel2)}</span>
                </div>
                <div className={styles.spacerBox} />
                <div className={styles.productIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-gallery')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel3)}</span>
                </div>
                <img className={styles.contentArrows} src={contentArrows} alt="infographic arrows" />
                <img className={styles.contentArrowsMobile} src={contentArrowsMobile} alt="infographic arrows mobile" />
              </ScrollAnimation>
            </div>
          </div>
          <div className={styles.colMid}>
            <ScrollAnimation animateIn="fadeIn" animateOnce delay={300}>
              <picture className={styles.productImg}>
                <source media={'(min-width: 1440px)'} srcSet={`${productDesktop} 2x, ${productDesktop} 3x`} />
                <source media={'(min-width: 768px)'} srcSet={`${productTablet} 1x, ${productTablet2x} 2x`} />
                <source media={'(max-width: 767px)'} srcSet={`${productMobile} 1x, ${productMobile2x} 2x`} />
                <img className={styles.teaserProduct} src={productDesktop} alt="product infographic" />
              </picture>
            </ScrollAnimation>
          </div>
          <div className={styles.colRight}>
            <ScrollAnimation className={styles.devicesContainer} animateIn="fadeIn" animateOnce delay={400}>
              <div className={styles.iconMobileOrder}>
                <span className={cn(styles.iconWrapper, styles['is-secondary'])}>
                  <span className={cn(styles.icon, 'icon-mobile')} />
                </span>
                <span className={styles.label}> {formatMessage(messages.iconLabel1)} </span>
              </div>
              <div className={styles.iconMobile}>
                <span className={cn(styles.iconWrapper, styles['is-secondary'])}>
                  <span className={cn(styles.icon, 'icon-mobile')} />
                </span>
                <span className={styles.label}>{formatMessage(messages.iconLabel1)}</span>
              </div>
              <div className={styles.spacerBox} />
              <div className={styles.iconTabletOrder}>
                <span className={cn(styles.iconWrapper, styles['is-tertiary'])}>
                  <span className={cn(styles.icon, 'icon-tablet')} />
                </span>
                <span className={styles.label}>{formatMessage(messages.iconLabel2)}</span>
              </div>
              <div className={styles.iconTablet}>
                <span className={cn(styles.iconWrapper, styles['is-tertiary'])}>
                  <span className={cn(styles.icon, 'icon-tablet')} />
                </span>
                <span className={styles.label}>{formatMessage(messages.iconLabel2)}</span>
              </div>
              <div className={styles.spacerBox} />
              <div className={styles.iconDesktopOrder}>
                <span className={cn(styles.iconWrapper, styles['is-accent'])}>
                  <span className={cn(styles.icon, 'icon-desktop')} />
                </span>
                <span className={styles.label}>{formatMessage(messages.iconLabel3)}</span>
              </div>
              <div className={styles.iconDesktop}>
                <span className={cn(styles.iconWrapper, styles['is-accent'])}>
                  <span className={cn(styles.icon, 'icon-desktop')} />
                </span>
                <span className={styles.label}>{formatMessage(messages.iconLabel3)}</span>
              </div>
              <img className={styles.devicesArrows} src={devicesArrows} alt="infographic device arrows" />
              <img className={styles.devicesArrowsMobile} src={devicesArrowsMobile} alt="infographic arrows mobile" />
            </ScrollAnimation>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(MightycInfographic)
