/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Aggregate, manage and distribute your content',
  contentText: 'With mightyC® you are able to aggregate and normalize content from any source.Use several third party sources and combine them with your own one.Manage all data and distribute it to every necessary channel.',
  contentLabel1: 'Non bookable content',
  contentLabel2: 'Own data source',
  contentLabel3: '  Media data',
  iconLabel1: 'Mobile',
  iconLabel2: 'Tablet',
  iconLabel3: 'Desktop'
})


