/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  backBtn: 'Back to overview',
  teaserText: `mightyC® is a content player that offers a
  collection of features, tools and APIs that manages your content.
  It helps to aggregate your content to make it reusable, and easy to distribute.`,
  btnText: 'Check it out',
  valueBoxTitle1: 'Aggregate',
  valueBoxTitle2: 'Manage',
  valueBoxTitle3: 'Distribute',
  valueTeaserTitle: 'Profit from a solid, flexible & affordable platform foundation',
  valueTeaserContent: `The TravelSandbox® is designed to be your quickstart foundation
  to guarantee a short time to market process. If you want to, it’s of course possible
  to extend the base with custom features and general modifications to get the perfect
  solution for your business. And to be sustainable and flexible it doesn’t matter on
  which product or content sources you rely.`,
  videoTeaserTitle: 'mightyC® at a glance',
  valueTeaserBtnText: 'Learn more about TravelSandbox®',
  descriptionTeaserTitle: 'Get your content easier than ever',
  descriptionTeaserContent: `mightyC® helps you to manage your existing content
  and open up new possible data sources.
  Through systematic normalization, they not only increase sustainability
  but also enable targeted playout in all systems and applications.`,
  descriptionTeaserBtn: 'Get in touch now'
})
