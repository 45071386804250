// @flow
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './MightyCPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

// components
import ProductTeaser2 from './../ProductTeaser2'
import ValueBox from './../ValueBox'
import Products from '../../../components/Products'
import MightycInfographic from './../InfographicTeasers/MightycInfographic'
import DescriptionTeaser from './../DescriptionTeaser'
import VideoTeaser from './../VideoTeaser'
import ValueTeaser from './../ValueTeaser'
import logoMightyc from '../../../images/logo/mightyc.svg'
import logoTsbo from '../../../images/logo/travelsandbox.svg'

// styles
import baseStyles from './MightyCPage.scss'

/* eslint-disable max-len */
const videoDesktop = (
  <StaticImage
    src="../../../images/products/mightyc/mightyc-videoteaser-desktop.png"
    alt=""
    objectFit="fill"
    className="imageCover"
  />
)
const valueDesktop = (
  <StaticImage
    src="../../../images/products/mightyc/mightyc-teaser3-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const bgDesktop = (
  <StaticImage
    src="../../../images/products/mightyc/mightyc-bg-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const teaser1Desktop = (
  <StaticImage
    src="../../../images/products/mightyc/mightyc-teaser1-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

import descriptionImgSrc from '../../../images/products/mightyc/mightyc-teaser4-desktop.svg'
const descriptionImg = <img src={descriptionImgSrc} alt="" className="image" />
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const productValues1 = ['mightycValue1', 'mightycValue2', 'mightycValue3']

const productValues2 = ['mightycValue1a', 'mightycValue2a', 'mightycValue3a']

const productValues3 = ['mightycValue1b', 'mightycValue2b', 'mightycValue3b']

const descriptionValues = [
  'mightycDescription1',
  'mightycDescription2',
  'mightycDescription3',
  'mightycDescription4',
  'mightycDescription5'
]

const MightyCPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ProductTeaser2
        logo={logoMightyc}
        backBtn={formatMessage(messages.backBtn)}
        teaserText={formatMessage(messages.teaserText)}
        btnText={formatMessage(messages.btnText)}
        imageDesktop={teaser1Desktop}
        bgImageDesktop={bgDesktop}
        hiddenPageTitle="mightyC"
      />
      <ValueBox
        productValues1={productValues1}
        productValues2={productValues2}
        productValues3={productValues3}
        icon1="icon-insert-link"
        icon2="icon-project-list"
        icon3="icon-management"
        title1={formatMessage(messages.valueBoxTitle1)}
        title2={formatMessage(messages.valueBoxTitle2)}
        title3={formatMessage(messages.valueBoxTitle3)}
      />
      <VideoTeaser
        title={formatMessage(messages.videoTeaserTitle)}
        videoUrl="https://www.youtube.com/embed/IEftJvd981g"
        imageDesktop={videoDesktop}
        type="mightyc"
      />
      <MightycInfographic />
      <ValueTeaser
        title={formatMessage(messages.valueTeaserTitle)}
        contentText={formatMessage(messages.valueTeaserContent)}
        btnText={formatMessage(messages.valueTeaserBtnText)}
        mobileLogo={logoTsbo}
        desktopLogo={logoTsbo}
        imageDesktop={valueDesktop}
        btnLink="/products/travelsandbox/"
      />
      <DescriptionTeaser
        title={formatMessage(messages.descriptionTeaserTitle)}
        contentText={formatMessage(messages.descriptionTeaserContent)}
        values={descriptionValues}
        btnText={formatMessage(messages.descriptionTeaserBtn)}
        btnLink="/contact/"
        imageDesktop={descriptionImg}
      />
      <Products type="mightyc" />
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(MightyCPage)
